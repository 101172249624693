jQuery(document).ready(function($) {
  const $document = $(document),
        $window = $(window),
        $body = $('body'),
        md = new MobileDetect(window.navigator.userAgent);

  // SAME HEIGHT
  function sameHeight(container) {
    let maxHeight = 0,
        $container = $(container);

    $container.each(function() {
      $container.css({'height':""});
      if ($(this).height()>maxHeight) {
        maxHeight = $(this).height();
      }
    });
    $container.height(maxHeight);
  }

  $window.on('load', function() {
    sameHeight('.equal');
    if ($window.width()<1024) {
      setTimeout(function () {
        $('.equal').css({'height':''});
      }, 100);
    } else {
      sameHeight('.equal');
    };
    sameHeight('.info__banners__item');
    // myths mobile
    if ($('.myths__slider').length&&md.phone()!==null) {
      $('.myths__slider__item .btn').removeClass('disabled');
    }
  });

  // Products page
  sameHeight('.products__list .prod-thumb__inner h3');
  sameHeight('.products__list .prod-thumb__inner .text');

  setTimeout(function () {
    sameHeight('.products__usage .prod-thumb__inner h3');
  }, 100);

  $window.on('resize', function() {
    sameHeight('.products__list .prod-thumb__inner h3');
    sameHeight('.products__list .prod-thumb__inner .text');

    setTimeout(function () {
      sameHeight('.products__usage .prod-thumb__inner h3');
    }, 100);

    // about page
    if ($window.width()<1024) {
      setTimeout(function () {
        $('.equal').css({'height':''});
      }, 100);
    } else {
      setTimeout(function () {
        sameHeight('.equal');
      }, 100);
    }
  });

  // main-page
  if ($('.main-slider').length) {
    $body.addClass('main-page');
  }

  // MYTHS Slider
  $('.myths__slider').on('init', function(){
    if($('.start-slide').hasClass('slick-current')){
      $('.myths__slider .slick-prev').hide();
    }
  });

  $('.myths__slider').on('beforeChange', function(){
    $(this).find('.slick-current').addClass('active-line');
    $(this).find('.slick-current .btn').addClass('disabled');
  });

  $('.myths__slider').on('afterChange', function(){
    $(this).find('.slick-current').next().removeClass('active-line');
    $(this).find('.slick-current .btn').removeClass('disabled');
    // arrow nav
    if($('.start-slide').hasClass('slick-current')){
      $('.myths__slider .slick-prev').hide();
    }
    else {
        $('.myths__slider .slick-prev').show();
    }
    // last-slide
    if($('.fourth-slide').hasClass('slick-current')&&md.mobile()==null){
      $(this).find('.slick-current').next().removeClass('active-line');
      $(this).find('.slick-current').next().find(' .btn').removeClass('disabled');
      $('.myths__slider').addClass('gradHide');
      $('.myths__slider .slick-next').hide();
    } else {
      $('.myths__slider .slick-next').show();
      $('.myths__slider').removeClass('gradHide');
    }
    // last slide
    if($('.last-slide').hasClass('slick-current')&&md.tablet()!==null){
      setTimeout(function () {
        $('.last-slide .icon--plane').css({'transform': 'translate3d(700px,0,0)'});
      }, 1000);
      $('.myths__slider .slick-next').hide();
      if ($window.width()<920) {
        setTimeout(function () {
          $('.last-slide .icon--plane').css({'transform': 'translate3d(400px, 72px, 0px)'});
        }, 1000);
      }
    } else {
      $('.last-slide .icon--plane').css({'transform': 'translate3d(0px,0,0)'});
      $('.myths__slider .slick-next').show();
      if ($window.width()<920) {
        $('.last-slide .icon--plane').css({'transform': 'translate3d(-96px, 72px, 0px)'});
      }
    }
  });

  $('.start-slide .btn').on('click', function (e) {
    e.preventDefault();
    $( '.myths__slider' ).slick('slickGoTo',1);
  });

  if (md.tablet()!==null||md.mobile()==null) {
    $('.myths__slider').slick({
      infinite: false,
      slidesToShow: 2,
      slideToScroll:1,
      dots: true,
      responsive: [
       {
         breakpoint: 1025,
         settings: {
           slidesToShow: 1,
         }
       },
     ]
    });
  }
  // PRODUCT slider
  $('.product-slide').slick({
    infinite: false,
    slidesToShow: 1,
    slideToScroll:1,
    autoplay : true,
    autoplaySpeed : 5000,
    dots: true
  });
  // ARTICLES slider
  $('.article-slider').slick({
    dots: true
  });
  $('.other-articles-slider').slick({
    infinite: false,
    slidesToShow: 3,
    slideToScroll:3,
    dots: true
  });

// MYTHS PAGE TEST
$window.on('load resize', function() {
  if ($window.width()>1366) {
    setTimeout(function () {
      mythsSlider.init.all();
    }, 200);
  } else if ($window.width()>=1280&&$window.width()<1366) {
    sameHeight('.myths__slider__item');
  }
  if ($window.width()<1280) {
    setTimeout(function () {
      mythsSlider.init.all();
      // sameHeight('.myths__slider__item');
    }, 200);
  }
  mythsDots();
});

let mythsSlider =  {
  init:{
    slideHeight: function () {
      $('.myths__slider__item').height($(window).height()-$('#footer').outerHeight()-$('#header').outerHeight()-$('.sanofi-header').outerHeight());
    },
    // gutter:function () {
    //   let gutter = parseInt($('.header__logo').css('margin-left'),10)+15;
    //
    //   $('.start-slide .center-wrap').css({
    //     marginLeft: gutter
    //   });
    // },
    all: function () {
      // this.gutter();
      this.slideHeight();
    },
  }
};

function mythsDots() {
  let $dotsList = $('.myths__slider .slick-dots li');

  $dotsList.each(function() {
     for (let i=0; i<$(this).length;i++){
       $(this).find('button').text($(this).index());
     }
   });
};
// mythsDots();

// up btn
function upBtn() {
  let $upBtn = $('.up-btn');
  $document.on('scroll', function () {
    if ($window.scrollTop()>=300) {
      $upBtn.fadeIn('slow');
    } else if ($window.scrollTop()<300) {
      $upBtn.fadeOut('slow');
    }
  });
  $upBtn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0},700);
  });
};
upBtn();

  // anchor-nav
  $("body").on("click tochstart","a.scroll", function (event) {
      event.preventDefault();
  		var id  = $(this).attr('href'),
      	  top = $(id).offset().top-30;
      $('body,html').animate({scrollTop: top}, 1500);
  });

  if (md.mobile()==null) {
    $('.custom-modal .modal-body').niceScroll();
  }

  // check label
  function checkLabel() {
    $('.custom-radio').on('click', function() {
      if ($(this).find('input').prop('checked')) {
        let sameRadio = $(this).find('input').attr('name');
        $('input[name="'+sameRadio+'"]').parent().not($(this)).removeClass('checked');
        $(this).addClass('checked');
      }
    });
  }

  checkLabel();

  // header nav
  $('.burger').on('click', function(e) {
    e.preventDefault();
    $('.header__nav').toggleClass('active');
    $body.toggleClass('stop-scroll');
    $('#blackout').fadeToggle();
  });

  $document.on('click', '#blackout', function() {
    console.log('black click');
    if ($('.header__nav').hasClass('active')) {
      $('.header__nav').removeClass('active');
    }
    $('#blackout').fadeOut();
    $body.removeClass('stop-scroll');
  });

  $('.close-mob').on('click', function(e) {
    e.preventDefault();
    $('.header__nav').removeClass('active');
    $('#blackout').fadeOut();
    $body.removeClass('stop-scroll');
  });

  $body.append('<div id="blackout"></div>');

});